<template>
    <div class="wrapper flex flex-column">
        <Header :search-style="{width:0}" :logo="logoUrl">
        </Header>
        <div class="flex-item">
            <div class="company-content container flex mt20">
                <div class="live-list flex-item">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="mh">
                            <div class="flex mb40 live-banner">
                                <a class="recommend-first">
                                    <img style="object-fit:cover;" class="rf-image" src="@/assets/images/company-banner.png" alt="直播标题">
                                </a>
                            </div>
                            <common-title title="企业动态" :isMore="false"></common-title>
                            <mescroll-vue :style="isHiddenMescroll ? 'overflow:hidden;' : ''" id="mescroll" ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
                                <div class="meeting-report pr10">
                                    <div v-for="(item, index) in infoListData" :key="index" class="hot-topic-box mt20">
                                        <!-- 资讯 -->
                                        <div v-if="item.show_type == 'news'" class="flex">
                                            <div class="tags news-tag mr10" :style="item.news_pic ? '' : 'position: static;'">资讯</div>
                                            <hot-topic-item path="/infomation-detail" containerWidth :type="item.type" :item="item">
                                            </hot-topic-item>
                                        </div>
                                        <!-- live -->
                                        <div class="flex" v-if="item.show_type == 'live'">
                                            <div class="tags meeting-tag mr10">直播</div>
                                            <live-item type="horizontal" :key="index" :item="item"></live-item>
                                        </div>
                                        <!-- meeting -->
                                        <div v-if="item.show_type == 'meeting'" class="flex" @click="toMeeting(item.href)">
                                            <div class="tags meeting-tag mr10">会议</div>
                                            <across-media-item class="flex-item" type="layoutRight" :key="index" :item="{ img: item.pic_url, title: item.cn_title ? item.cn_title : item.en_title }">
                                                <template v-slot:bottom>
                                                    <div>
                                                        <div class="row-1" v-if="item.start_time">
                                                            <i class="med med-31shijian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.start_time.slice(0, 10) }}</span>
                                                        </div>
                                                        <div class="row-1" v-if="item.venue_name_cn">
                                                            <i class="med med--didian"></i>
                                                            <span class="color999 fs14 ml5">{{ item.venue_name_cn }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </across-media-item>
                                        </div>
                                        <!-- 项目 -->
                                        <div v-if="item.show_type == 'product'" class="flex" @click="toProductDetail(item)">
                                            <div class="tags meeting-tag mr10">项目</div>
                                            <across-media-item class="flex-item" :key="index" :item="{...item, desc: item.name}">
                                            </across-media-item>
                                        </div>
                                    </div>
                                    <a-empty v-if="!infoListData.length && !loading" />
                                </div>
                            </mescroll-vue>
                        </div>
                    </a-spin>
                </div>
                <div class="live-right">
                        <div class="func">
                            <div class="func-content">
                                <div class="flex pr10 font16 func-item mb10">
                                    <div class="flex y-center">
                                        <img src="@/assets/images/位图.png" alt="">
                                        <div class="t-l ml10">
                                            <div class="title">搭建药械信知平台</div>
                                            <div class="describe">传播前言知识</div>
                                        </div>
                                    </div>
                                    <div class="med med-right flex y-center"></div>
                                </div>
                                <div class="flex pr10 font16  func-item mb10">
                                    <div class="flex y-center">
                                        <img src="@/assets/images/Group 1379_2.png" alt="">
                                        <div class="t-l ml10">
                                            <div class="title">增加曝光度</div>
                                            <div class="describe">提升企业品牌</div>
                                        </div>
                                    </div>
                                    <div class="med med-right flex y-center"></div>
                                </div>
                                <div class="flex pr10 font16  func-item mb10">
                                    <div class="flex y-center">
                                        <img src="@/assets/images/Group 1379_1.png" alt="">
                                        <div class="t-l ml10">
                                            <div class="title">问卷调查</div>
                                            <div class="describe">获取临床数据</div>
                                        </div>
                                    </div>
                                    <div class="med med-right flex y-center"></div>
                                </div>
                                <div class="flex pr10 font16  func-item mb10">
                                    <div class="flex y-center">
                                        <img src="@/assets/images/Group 1379.png" alt="">
                                        <div class="t-l ml10">
                                            <div class="title">学术分享</div>
                                            <div class="describe">会议、直播一步到位</div>
                                        </div>
                                    </div>
                                    <div class="med med-right flex y-center"></div>
                                </div>
                            </div>
                        </div>
<!--                        <a-affix :offset-top="0">-->
                        <div class="companyList mt30">
                            <common-title title="推荐企业" path="/company/list"></common-title>
                            <div class="companyList-item flex x-left y-center mb20" v-for="(item, index) in companyList" :key="index" @click="toCompanyList(item.id)">
                                <div class="companyList-img">
                                    <img :src="item.index_logo | urlFilter(250,'avatar') || require('@/assets/images/committee-logo.jpg')" alt="">
                                </div>
                                <div class="t-l pl10">
                                    <div class="companyList-name">{{item.name}}</div>
                                    <div class="companyList-num mt10">
                                        <span class="mr10 pr10">关注：{{item.fans_count ? item.fans_count : 0}}</span>
                                        <span>浏览：{{item.committee_hits ? item.committee_hits : 0}}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
<!--                    </a-affix>-->

                        <layout-right style="overflow:hidden;" :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import hotTopicItem from '@/components/hot-topic-item.vue';
import commonTitle from '@/components/common-title';
import Header from '@/components/layout/layout-header';
import layoutRight from '@/components/layout/layout-right';
import Footer from '@/components/layout/layout-footer';
import courseItem from '@/components/courser-list-item';
import liveItem from '@/components/live-item.vue';
import meetingItem from '@/components/meeting-item.vue';
import { organizationLogo } from '@/config/const'
import { videoJumpDetail } from '@/utils/jumpPageMethods';
import mescrollMixin from '@/utils/mescroll.mixin';
import storage from 'store';
import { mapState } from 'vuex';
import { debounce } from '@/utils/common'
import acrossMediaItem from '@/components/across-media-item.vue'
export default {
    mixins: [mescrollMixin],
    name: "CompanyHome",
    components: {
        commonTitle,
        Header,
        layoutRight,
        Footer,
        hotTopicItem,
        courseItem,
        liveItem,
        meetingItem,
        acrossMediaItem
    },
    data() {
        return {
            isHiddenMescroll: true, // 是否隐藏mescroll滚动条
            infoListData:[],
            companyList: [], // 机构号列表
            logoUrl: organizationLogo,
            bigImg: 'https://files.sciconf.cn/medcon/2023/03/20230325/2023032516265293610214857.jpg!wx360', //推荐视频大图
            bigVideoId: '',
            dataCount: 0,
            layoutRightConfig: {
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    moreLink: '/meeting-list?year='+(new Date()).getFullYear()+'&month=' + ((new Date()).getMonth() + 1),
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                }
            },
            loading: true,
            subject_id:'',
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        toCompanyList (id) {
            this.$router.push({
                path: '/company/index',
                query: {
                    company_id: id
                }
            })
        },
        // 获取企业列表
        getCompanyList () {
            return this.request.post('CommitteeList',{type:'30',subject:'',size: 5})
        },
        videoJumpDetail,
        pageInit() {
            let requestArr = [
                this.getCompanyList()
            ]
            Promise.all(requestArr).then(res => {
                // 机构排行榜
                this.companyList = res[0].data.slice(0, 5)
            })
        },
        // 搜索
        handleSearchEvent() {
            this.mescroll.resetUpScroll();
        },
        // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
        upCallback(page, mescroll) {
            let data = {
                page: page.num
            }
            // 联网请求
            this.request.post('getCompanyIndex', data).then((res) => {
                let arr = res.data || [];
                // 如果是第一页需手动置空列表
                if (page.num === 1) {
                    this.infoListData = arr;
                } else {
                    // 把请求到的数据添加到列表
                    this.infoListData = this.infoListData.concat(arr)
                }
                // 数据渲染成功后,隐藏下拉刷新的状态
                this.$nextTick(() => {
                    mescroll.endSuccess(arr.length, 0);
                    this.loading = false
                })
            }).catch((e) => {
                // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
                mescroll.endErr()
                this.loading = false
            })
        },
        winScrollHandle (e) {
            if (document.documentElement.scrollTop >=500) {
                this.isHiddenMescroll = false
            } else {
                this.isHiddenMescroll = true
            }
        },
        toMeeting (href) {
            window.open(href, '_blank');
        },
        toProductDetail (item) {
            this.$router.push({
                path: '/company/product-list',
                query: {
                    company_id: item.committee_id,
                    class_id: item.id,
                    title: item.title
                }
            })
        }
    },
    created() {
        this.pageInit()
        window.addEventListener('scroll', debounce(this.winScrollHandle, 200))
    }
}
</script>

<style lang="less" scoped>
.hot-topic-box {
    position: relative;
}
.mescroll-totop {
    height: 2rem;
}
.mescroll {
    height: 100vh;
    margin-top: 20px;
}
.companyList  {
    background: #FFFFFF;
}
.companyList-item {
    height: 88px;
    background: #FFFFFF;
    border: 1px solid #EFF4FF;
    cursor: pointer;
    .companyList-img {
        width: 120px;
        overflow: hidden;
        img {
            width: 100%;
            object-fit: cover;
            max-height: 80px;
        }
    }
    .companyList-name {
        font-weight: bold;
        font-size: 16px;
    }
    .companyList-num {
        font-size: 14px;
        color: #999999;
        display: flex;
        align-items: center;
        & span:nth-child(1) {

            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 10px;
                background: #CCCCCC;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
    }
}
.wrapper {
    min-height: 100vh;
    background: url("~@/assets/images/live-nav-top-bg.png") repeat-x;
    background-size: 1920 auto;
}
.video-list-title {
    display: block;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
        position: relative;
        padding-right: 10px;
        margin-right: 10px;
        color: #253E6D;
        display: inline-block;
        &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 15px;
            background: #999999;
            right: -10px;
            top: 50%;
            transform: translate(-2px, -50%);
        }
    }
}
.filter-wrapper {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
}

.filter-condition {
    background: #fff;
    overflow: hidden;
    height: 8px;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}

.company-content {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.live-list {
    margin-right: 30px;

    .mh {
        min-height: 500px;
    }
}
// 直播推荐
.recommend-first {
    width: 100%;
    .rf-image {
        width: 100%;
    }
}


.live-right {
    width: 400px;

    .paper-writing {
        height: 24px;
        display: flex;
        cursor: pointer;
    }

    .apply-teacher {
        width: 100%;
        height: 92px;
    }
}
.func {
    background: #FFFFFF;
}
.func-item {
    height: 100px;
    background: #F7FBFF;
    border-radius: 5px;
    border: 1px solid #F2F3F5;
    padding-left: 30px;
    box-sizing: border-box;
    cursor: pointer;
    & div:nth-child(1) {
        img {
            width: 40px;
            height: 40px;
        }
        flex: 1;
        justify-content: left;
        .title {
            font-weight: bold;
            font-size: 18px;
            color: #333333;
        }
        .describe {
            color: #999999;
            font-size: 14px;
        }
    }
}
@media screen and (max-width:768px){
    .footer {
        display: none !important;
    }
    .live-right {
    /deep/.ant-affix {
        position: static !important;
    }
    }
    
    .meeting-list {
        display: block;
    }
    .wrapper{
        .mh {
            padding: 0 10px;
        }
         .filter-condition.active{
            margin-top:10px;
            height:40px;
            line-height:40px;
            .container{
                padding:0 10px;
                display: flex;
            }
        }
        .filter-wrapper{
            padding: 2px 0;
            border-radius: 0;
            position: sticky;
            top: 50px;
            background: #f2f4fa;
            z-index: 9;
        }
        .company-content{
            margin-top: 0px;
            display: flex;
            padding: 0;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            .live-list{
                width: 100%;
                .live-banner{
                    display: block;
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    margin-bottom: 20px;
                    .recommend-first{
                        width: 100%;
                        height: auto;
                        .rf-image{
                            height: auto;
                        }
                    }
                }
                .video-list{
                    width: 100%;
                    overflow: hidden;
                }
            }
            .live-right{
                width: 100%;
                margin-top: 30px;
                padding: 0 10px;
            }
        }
    }
}
</style>